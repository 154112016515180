import React, { useRef, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { queryContext } from '../contexts/QueryContext';
import { DeleteOutlineOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    // flex: " 0 0 80px",
    background:"#000",
    alignItems: "center",
    padding: "0px 0",

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0 20px',
      justifyContent:'space-between'
    }
  },
  clearBtn: {
    color: '#fff',
    position: 'relative',
    width: "80px",
    height: '80px',
    marginTop: '20px',
    marginRight:'10px',
    fontSize: '16px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      marginLeft: '20px'
    },

    '&:hover': {
      color: '#06AFF2'
    }
  },
}));


const Menu = () => {
  const classes = useStyles();
  const { load, openSnackBar, dropTable } = useContext(queryContext);

  // const hanldeFileChange = async () => {
  //   const file = inputRef.current.files[0];
  //   const fileType = file.name.split('.')[1];
  //   if (!file || fileType !== 'csv') {
  //     openSnackBar("type error");
  //     return;
  //   }

  //   const fd = new FormData();
  //   fd.append('file', file);

  //   try {
  //     const { data } = await load(fd);
  //     if (data[0].status) {
  //       openSnackBar(data[0].msg);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     inputRef.current.value = '';
  //   }
  // };

  const hanldeClear = async () => {
    try {
        window.localStorage.removeItem("qaList");
        openSnackBar("历史记录已清除！");
        window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const inputRef = useRef(null);
  return (
    <div className={classes.root}>
      {/* <img src="/favicon.png" alt="logo" style={{ width: "40px", height: '40px' }}></img> */}

      {/* <div className={classes.uploaderBt}>
        <CloudUploadIcon />
        <p>Upload</p>
        <input type="file" ref={inputRef} className={classes.uploader} onChange={hanldeFileChange} />
      </div> */}

      <div className={classes.clearBtn} onClick={hanldeClear}>
        <DeleteOutlineOutlined/>
        <p>清除历史</p>
      </div>
    </div>
  );
};

export default Menu;