import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import { Container, ListItemText, Menu, MenuItem, MenuList, Paper } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  cardGrid: {
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    height:0,
    paddingTop: '30.25%', // 16:9
    backgroundColor: 'rgba(0, 0, 0, 0.5)', 
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  paper:{
    display:'flex',
    flexDirection:"row",
    paddingLeft:"180px",
    backgroundColor:"#fff",
    
  }
}));
const menusList=[
    {
        id:0,
        name:"编程学习",
    },
    {
        id:1,
        name:"嵌入式&硬件",
    },
    {
        id:2,
        name:"人工智能",
    }
]
const bc = [{
  id:"0",
  image:"https://gimg3.baidu.com/search/src=http%3A%2F%2Fgips1.baidu.com%2Fit%2Fu%3D2436897185%2C1565664841%26fm%3D3030%26app%3D3030%26f%3DJPEG%3Fw%3D200%26h%3D133%26s%3DD38292461050CC6F0D9E61410300F0BA&refer=http%3A%2F%2Fwww.baidu.com&app=2021&size=f242,150&n=0&g=0n&q=100&fmt=auto?sec=1715965200&t=6602aa8185e33f8e29c05020b85f351b",
  title:"菜鸟教程",
  description:"前端 HTML CSS JS React Vue , 移动端 Android ios ,服务端 JAVA Python C++ C , 数据库 Mysql SQLlite MongoDB Redis 等入门学习",
  link:"https://www.runoob.com"
},{
  id:"1",
  image:"https://mianshi8.net/layui/images/logo.png",
  title:"菜鸟教程",
  description:"前端 HTML CSS JS React Vue ",
  link:"https://mianshi8.net"
}
];

const embedding = [{
    id:"0",
    image:"https://rymcu.com/_nuxt/img/rymcu.3b63a55.png",
    title:"RYMCU 嵌入式",
    description:"单片机、嵌入式学习",
    link:"https://rymcu.com/"

  },
  {
    id:"1",
    image:"https://jiangxiekeji.com/img/logo.png",
    title:"江协科技",
    description:"51单片机、STM32 入门教程及开发板提供",
    link:"https://jiangxiekeji.com/"
  },
  {
    id:"2",
    image:"https://www.elecfans.com/static/main/img/site-logo-2018.png?v4",
    title:"电子发烧友",
    description:"学习单片机编程和嵌入式系统设计，开发各种嵌入式设备；电子元件、电路设计、模拟和数字电子技术等方面的知识；",
    link:"https://www.elecfans.com/"
  }
  ];
  const ai = [{
    id:"0",
    image:"https://g.alicdn.com/sail-web/maas/1.12.8/static/modelscopeIcon.cd89353f.svg",
    title:"魔搭社区",
    description:"大模型学习网站",
    link:"https://www.modelscope.cn/"
  }
  ];
  const cards=[
    bc,embedding,ai
]
export default function TechNav() {
    const [contents,setContents]=useState(bc)
  const classes = useStyles();
const doClick=(id)=>{
    setContents([...cards[id]])
}
  return (
    <React.Fragment>
      <main  >
        <Paper className={classes.paper}>
        <MenuList  style={{borderColor:"red",border: '1px solid #d3d4d5', height:"100%"}}>
            {menusList.map((menu)=>{
                    return (
                        <MenuItem  key={menu.id} onClick={()=>{
                            doClick(menu.id)
                        }} >{menu.name}
                         </MenuItem>
                    )
            })}
          
         
        </MenuList>
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {contents.map((card) => (
              <Grid item key={card.id} xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={card.image}
                    title="Image title"
                    // component={"img"}
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h6" component="h6">
                    {card.title}
                    </Typography>
                    <Typography>
                    {card.description}
                    </Typography>
                  </CardContent>
                  <CardActions>
                   <Link  href={card.link} target='_blank'>{card.link}</Link>
                  </CardActions>
                </Card> 
              </Grid>
            ))}
          </Grid>
        </Container>
      </Paper>
     
      </main>
    </React.Fragment>
  );
}

