import React, { useState, useContext, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextareaAutosize,
  Snackbar,
  CircularProgress,
  Button,
  Icon,
} from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import { queryContext } from "../contexts/QueryContext";
import { texts } from "../lan";
// 引入编辑器组件
import BraftEditor from 'braft-editor'
// 引入编辑器样式
import 'braft-editor/dist/index.css'
const ERROR_MSG = "系统繁忙，请稍后重试！";
const FRIENDLY_MSG="抱歉未找到您需要的信息！";
const QUESTION_PREFIX="我想了解 ";
const lanKey = window._env_ && window._env_.LAN === "cn" ? "cn" : "en";
const { tip, week, placeholder, notEmpty } = texts[lanKey];
var markdown = require("markdown").markdown;
let contents = "";
let sendDisabled=false;
const QA = (props: any) => {

  const textArea = useRef(null);
  const resultContainer = useRef(null);
  const [time, setTime] = useState("");
  const [qaList, setQaList] = useState([
    {
      type: "answer",
      question: tip,
      answers: tip,
      isRichText: false,
      recommendAnswerList: []
    },
  ]);

  const [recommendAnswerList, setRecommendAnswerList] = useState([
  ]);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const isMobile = props.isMobile;
  const { search, getAnswer } = useContext(queryContext);


  const useStyles = makeStyles(theme => ({
    wrapper: {
      display: "flex",
      width: "100%",
      flex: 1,
      flexDirection: "column",
      // backgroundColor:"white"
    },
    content: {
      flex: 1,
      maxHeight: "calc(100vh  - 210px)",
      overflowY: "auto",
      color: "#000",
      padding: isMobile ? "20px" : "5px",
      fontSize: isMobile ? "12px" : "15px",
    },
    textarea: {
      // flex:1,
      display:'flex',
      flexDirection:'row',
      // marginBottom:'0px',
      // margin:"10px",
      // position: "relative",
      // flex: "0 0 154px",
      padding: "10px 0px 10px 10px",
      // backgroundColor: "red",
      borderTop: "1px solid #B0B0B9",
      "& :focus": {
        outline: "none",
      },

      // backgroundColor:'red',
    },
    item: {
      display: "flex",
      marginTop: "16px",
    },
    avatar: {
      width: "40px",
      height: "40px",
      borderRadius: "50%",
      backgroundSize: "40px",
      backgroundPosition: "center",
      flexShrink: 0,
      backgroundImage: `url(
        "/robot.png"
      )`,

      // [theme.breakpoints.down('xs')]: {
      //   width: "40px",
      //   height: "40px",
      //   backgroundSize: "100%",
      // }
    },
    avatar_me: {
      width: "40px",
      height: "37px",
      borderRadius: "0%",
      opacity: 0.9,
      backgroundColor: 'white',
      backgroundSize: "40px",
      backgroundPosition: "center",
      flexShrink: 0,
      backgroundImage: `url(
        "/me.png"
      )`,

      // [theme.breakpoints.down('xs')]: {
      //   width: "40px",
      //   height: "40px",
      //   backgroundSize: "100%",
      // }
    },
    text: {
      position: "relative",
      display: "flex",
      marginLeft: "20px",
      alignItems: "center",
      backgroundColor: "#fff",
      padding: "14px 21px",
      maxWidth: "500px",
      lineHeight: '1.2rem',
      color: "#000",
      borderRadius: "10px",
      ".question &": {
        backgroundColor: "#C7EDFF",
      },
      ".answer &": {
        backgroundColor: "#E5E5E5",
      },
      "& p": {
        maxWidth: "90%",
      },
    },
    send: {
      margin: theme.spacing(1),
      height: "50px",
      width: "100px",
      marginRight:"1px",
      borderRadius:"20px"
    },
    triangle: {
      position: "absolute",
      top: "16px",
      width: 0,
      height: 0,
      borderTop: "10px solid transparent",
      borderBottom: "10px solid transparent",
      ".question &": {
        borderLeft: "10px solid #e5e5e5",
      },
      ".answer &": {
        borderRight: "10px solid #fff",
      },

    },
    answerItem: {
      color: "#06aff2",
      cursor: 'pointer',

      "&:hover": {
        textDecoration: 'underline'
      }
    }
  }));
  const classes = useStyles({});

  const handleSend = async (e) => {
  if(sendDisabled){
    return;
  }
   
    contents = "";
    const value = textArea.current.value;
    if (!value.trim()) {
      setMessage(notEmpty);
      setOpen(true);

      setTimeout(() => {
        setOpen(false);
      }, 800);
      return;
    }
    sendDisabled=true;
    setQaList((list: any) => {
      let text = textArea.current.value;
      textArea.current.value = null;
      return [
        ...list,
        { type: "question", recommendAnswerList: [], question: text, answers: [], },
        { type: "answer", loading: true, recommendAnswerList: [], question: "", answers: [], },
      ];
    });
    let eventSource = null;
    try {
      eventSource = new EventSource(
        `${window._env_.API_URL}/base-server/openAi/streamAsk?question=${encodeURIComponent(
          QUESTION_PREFIX+value
        )}`
      );
    } catch (e) {
      dealException()
    }
    eventSource.onmessage = function (event) {
      contents = contents + event.data;
      let tmpContents = markdown.toHTML(contents).replace(/&lt;/g, "<").replace(/&gt;/g, ">");
      setQaList((list) => {
        let tempList = list.map((i, idx) => {
          if (idx === list.length - 1) {
            return {
              type: 'answer',
              loading: false,
              question: "",
              recommendAnswerList: [],
              answers: tmpContents,
              isRichText: false
            };
          }
          return {
            ...i,
            loading: false
          };
        });
        window.localStorage.setItem("qaList", JSON.stringify(tempList))
        return tempList;
      })
    };
    eventSource.onerror = async function (event) {
      sendDisabled=false;
      eventSource.close();
      console.log(contents);
      let tmpContents = markdown.toHTML(contents).replace(/&lt;/g, "<").replace(/&gt;/g, ">");
      console.log(tmpContents)
      
      try {
        const { data } = await search({ question: value });
        const { code, msg, } = data;
        let qaSearchVO = data.data;
        let isRichText = false;
        const recommendAnswerList = qaSearchVO.recommendAnswerList;
        if (code == 200) {
          if(tmpContents==""&&recommendAnswerList.length==0){
            tmpContents=FRIENDLY_MSG;
          }
          setQaList((list) => {
            let tempList = list.map((i, idx) => {
              if (idx === list.length - 1) {
                return {
                  type: 'answer',
                  loading: false,
                  question: "",
                  recommendAnswerList: recommendAnswerList,
                  answers: tmpContents,
                  isRichText: isRichText
                };
              }
              return {
                ...i,
                loading: false
              };
            });
            window.localStorage.setItem("qaList", JSON.stringify(tempList))
            return tempList;
          })
            ;
        }
      } catch (error) {
        dealException();
      } finally { }

    };
  }

  const dealException = () => {
    sendDisabled=false;
    setQaList((v: any) => {
      return v.map((i, idx) => {
        if (idx === v.length - 1) {
          return {
            type: 'answer',
            loading: false,
            answers: ERROR_MSG,
            question: ""
          };
        }
        return {
          ...i,
          loading: false
        };
      });
    });
  }

  const hanldeGetAnswer = async (question: any) => {
    setQaList((v) => {
      return [...v,
      {
        type: 'question',
        loading: false,
        recommendAnswerList: [],
        answers: null,
        question: question.question,
        isRichText: false
      },
      {
        type: 'answer',
        loading: true,
        recommendAnswerList: [],
        answers: null,
        question: '',
        isRichText: false
      }
      ];
    })

    try {
      const { data } = await getAnswer(question.id);
      const { code, msg } = data;
      if (code == 200) {
        setQaList((v) => {
          let tempList = v.map((i, idx) => {
            if (idx === v.length - 1) {
              return {
                type: 'answer',
                loading: false,
                recommendAnswerList: [],
                question: "",
                answers: data.data?.answer,
                isRichText: true
              };
            }
            return {
              ...i,
              loading: false
            };
          });
          window.localStorage.setItem("qaList", JSON.stringify(tempList));
          return tempList;
        });
      }
    } catch (error) {
      setQaList((v: any) => {
        return v.map((i, idx) => {
          if (idx === v.length - 1) {
            return {
              type: 'answer',
              loading: false,
              answers: [ERROR_MSG],
              question: ""
            };
          }
          return {
            ...i,
            loading: false
          };
        });
      });

    }

  };

  useEffect(() => {
    const addZero = (num) => (num < 10 ? `0${num}` : num);
    const getTime = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = addZero(now.getMonth() + 1);
      const day = addZero(now.getDay());
      const date = now.getDate();
      const hour = addZero(now.getHours());
      const minutes = addZero(now.getMinutes());
      const seconds = addZero(now.getSeconds());
      return `${year}/${month}/${date}  ${week[parseInt(day)]
        } ${hour}:${minutes}:${seconds}`;
    };
    setTime(getTime());
    let qaList = window.localStorage.getItem("qaList");
    if (qaList != undefined) {
      setQaList((list) => {
        return [
          ...JSON.parse(qaList)
        ];
      });
    }
  }, []);

  useEffect(() => {
    resultContainer.current.scrollTop = 1000000;
  }, [qaList]);

  const handleKeyDown = (e) => {
    console.log(e.keyCode);
    if (e.keyCode === 13) {
      // handleSend(e);
      e.preventDefault();
    }
  };

  return (
    <div className={classes.wrapper}>
      <div ref={resultContainer} className={classes.content}>
        <p style={{ textAlign: "center" }}>{time}</p>
        {qaList.map((v: any, i) => {
          if (v.type === "answer") {
            return (
              <div className={`${classes.item} answer`} key={i}>
                <div className={classes.avatar}></div>
                <div className={classes.text}>
                  <div
                    className={`${classes.triangle}`}
                    style={{ left: "-10px" }}
                  ></div>
                  {v.loading ? (
                    <CircularProgress
                      style={{ color: "#333" }}
                    ></CircularProgress>
                  ) : (
                    <>
                      <div>
                        {v.answers &&
                          <BraftEditor
                            value={BraftEditor.createEditorState(v.answers)}
                            controls={[]}
                            contentStyle={{ height: "100%", maxHeight: "500px" }}

                            controlBarStyle={{ display: "none" }}

                          />
                        }
                        {
                          v.recommendAnswerList?.length >= 1 && <>
                            <br />
                            <h3>   <strong>猜您想看:</strong></h3>
                            <ul>
                              {
                                v.recommendAnswerList?.map(i => (
                                  <li
                                    className={classes.answerItem}
                                    key={i.id}
                                    onClick={() => hanldeGetAnswer(i)}
                                  >
                                    {i.question}
                                  </li>
                                ))
                              }
                            </ul>
                          </>
                        }
                      </div>
                    </>
                  )}
                </div>
              </div>
            );
          } else {
            return (
              <div
                className={`${classes.item} question`}
                style={{ flexDirection: "row-reverse" }}
                key={i}
              >
                <div className={classes.avatar_me}></div>
                <div className={classes.text} style={{ margin: "0 20px 0 0", width: '100%' }}>
                  <p >{v.question}</p>
                </div>
              </div>
            );
          }
        })}
      </div>
      <div className={classes.textarea}>
        <TextareaAutosize
          ref={textArea}
          aria-label="empty textarea"
          placeholder={placeholder}
          minRows={4}
          style={{
            width: "100%",
            boxSizing: "border-box",
            border: "none",
            borderRadius: "10px",
            color: "#000",
            fontFamily: "Roboto",
            fontSize: "15px",
            borderColor: "#4e6ef2"
          }}
          onKeyDown={handleKeyDown}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSend}
          className={classes.send}
        >
          发送
        </Button>
        {/* <div className={classes.send}>
          <Button></Button>
          <ArrowForward fontSize="large" onClick={handleSend} color="primary" ></ArrowForward>
        </div> */}
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        key="top center"
        open={open}
        message={message}
      />
    </div>
  );
};

export default QA;
