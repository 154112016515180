export const texts = {
  en: {
    tip: "您好，我是技术库智能机器人， 请输入您的问题!",
    week: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
    placeholder: "请输入您的问题!",
    title: "技术库问答系统",
    notEmpty: "请输入您的问题!",
  },
  cn: {
    tip: "您好，我是技术库智能机器人， 请输入您的问题!",
    week: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
    placeholder: "请输入您的问题!",
    title: "技术库问答系统",
    notEmpty: "请输入您的问题!",
  },
};
