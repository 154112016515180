import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "./components/common/Navbar";
import Home from "./pages/Home";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import TechNav from "./pages/TechNav";
import Footer from "./components/common/Footer";
function App() {
  const isMobile = !useMediaQuery("(min-width:768px)");
  const useStyles = makeStyles((theme) => ({
    main: {
      height: "100%",
      backgroundColor: "white",
      margin: isMobile?  "0px" :"0px 0px"
    },
  }))
  const classes = useStyles();
  return (
    <Router>
      <div className={classes.main}>
        {!isMobile&&<Navbar />}
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/techNav" component={TechNav} />

        </Switch>
        <Footer></Footer>
      </div>
    </Router>
  );
}
export default App;
