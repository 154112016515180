import React from "react";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  navlinks: {
    // marginLeft: theme.spacing(20),
    // marginRight: theme.spacing(20),
    display: "flex",
  },
  logo: {
    // flexGrow: "1",
    cursor: "pointer",
  },
  link: {
    display: "inline-block",
    verticalAlign:'middle',
    lineHeight:'64px',
    height:'64px',
    margin:"auto 0px",
    textDecoration: "none",
    borderRadius:"8%",
    padding: "0px 8px",
    color: "black",
    fontSize: "14px",
    // fontWeight: 600,
    "&:hover": {
      color: "#00aae7",
      // border: "1px solid",
      // textDecoration: "underline",
    },
  },
  title:{
    margin:"auto 5px",
    fontSize:"19px",
    fontWeight:400
  }
}));

function Navbar() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <AppBar position="static" color="transparent">
      <Toolbar>
        <Typography variant="h5" className={classes.logo}>
        </Typography>
          <div className={classes.navlinks}>
            <div style={{marginRight:"30px",display:'flex',justifyContent:'center',alignItems:'center'}}>
            <a href="/" style={{display:"inline-block"}}>
              <img style={{margin:"auto 0", float:"left",borderRadius:"4px"}} height={"30px"} width={"30px"} src="/logo.png"></img>
            </a>
              <a href="/" style={{display:"inline-block" ,textDecoration:"none",color:"black"}}>
              <span className={classes.title}>技术库</span>
              </a>
            </div>
            <div>
            <Link to="/" className={classes.link}>
              首页
            </Link>
            <Link to="/techNav" className={classes.link} >
              技术导航
            </Link>
            {/* <Link to="/about" className={classes.link}>
              技术网站
            </Link>
            <Link to="/contact" className={classes.link}>
              技术资料
            </Link>
            <Link to="/contact" className={classes.link}>
              代码运行
            </Link>
            <Link to="/contact" className={classes.link}>
              在线工具
            </Link>
            <Link to="/contact" className={classes.link}>
              工具下载
            </Link>
            <Link to="/test" className={classes.link} >
              test
            </Link> */}
            </div>
           
          </div>
      </Toolbar>
    </AppBar>
    
  );
}
export default Navbar;
