import { Divider, Link, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="">
        技术库
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
      <Link href="https://beian.miit.gov.cn/" target="_blank"> 皖ICP备2024047677号-1</Link>
                  <br></br>
                   
                    本站内容来源于网络及人工智能模型，其内容的准确性和完整性无法保证，仅供学习参考！
    </Typography>
  );
}
const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    // padding: theme.spacing(6),
  },
}));

const Footer=()=>{
  const classes = useStyles();
    return (
    <footer className={classes.footer}>
    <Divider variant='fullWidth' ></Divider>
    <Typography variant="h6" align="center" gutterBottom>
      {/* Footer */}
    </Typography>
    <Copyright />
  </footer>
    )
}
export default Footer;





